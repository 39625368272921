import {tns} from 'tiny-slider';

require('fslightbox');


window.addEventListener('load', function () {

    function bodyFixPosition() {
      setTimeout(function () {
        if (!document.body.hasAttribute('data-body-scroll-fix')) {
          let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
          document.body.setAttribute('data-body-scroll-fix', scrollPosition); // Cтавим атрибут со значением прокрутки
          document.body.style.overflow = 'hidden';
          document.body.style.position = 'fixed';
          document.body.style.top = '-' + scrollPosition + 'px';
          document.body.style.left = '0';
          document.body.style.width = '100%';
        }
      }, 15);
    }

    function bodyUnfixPosition() {
      if (document.body.hasAttribute('data-body-scroll-fix')) {
        let scrollPosition = document.body.getAttribute('data-body-scroll-fix');

        document.body.removeAttribute('data-body-scroll-fix');

        document.body.style.overflow = '';
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.left = '';
        document.body.style.width = '';

        window.scroll(0, scrollPosition);
      }
    }

    /** MOBILE MENU **/
    const burger = document.querySelector('.header__burger')
    const menu = document.querySelector('.header__nav')
    const menuOverlay = document.querySelector('.header__overlay')
    const closeMenuButton = document.querySelector('.nav__close')

    const openMenu = () => {
      document.body.style.overflow = 'hidden'
      bodyFixPosition()
      menu.classList.add('visible')
      menuOverlay.classList.add('visible')
    }

    const closeMenu = () => {
      document.body.style.overflow = 'visible'
      bodyUnfixPosition()
      menu.classList.remove('visible')
      menuOverlay.classList.remove('visible')
    }

    if (burger) burger.addEventListener('click', openMenu)
    if (closeMenuButton) closeMenuButton.addEventListener('click', closeMenu)
    if (menuOverlay) menuOverlay.addEventListener('click', closeMenu)
    /** EMD MOBILE MENU **/


    /** MOBILE SUBMENU **/
    const itemsWithSubmenu = document.querySelectorAll('li.menu-item-has-children')

    if (itemsWithSubmenu) {
      itemsWithSubmenu.forEach(item => {

        item.querySelector('a').addEventListener('click', e => {
          e.preventDefault()

          const subMenu = item.querySelector('ul')

          if (item.dataset.open !== 'true') {
            item.classList.add('open')
            item.dataset.open = 'true'
            subMenu.style.maxHeight = `${subMenu.scrollHeight}px`
          } else {
            item.classList.remove('open')
            item.dataset.open = 'false'
            subMenu.style.maxHeight = ''
          }

        })

      })
    }
    /** END SUBMENU **/


    /** SLIDER ON HOME PAGE FIRST SCREEN **/
    const uspTrack = document.querySelector('.usp__track')
    if (uspTrack) {

      const uspSlider = tns({
        container: '.usp__track',
        items: 1,
        slideBy: 'page',
        autoplay: false,
        nav: false,
        prevButton: '.usp__button--prev',
        nextButton: '.usp__button--next',
      });

    }
    /** END Slider on home page **/


    /** PARTNERS SLIDER IN FOOTER **/
    const partnersTrack = document.querySelector('.partners-slider__track')
    if (partnersTrack) {

      const partnersSlider = tns({
        container: '.partners-slider__track',
        slideBy: 2.6,
        autoplay: false,
        nav: false,
        autoWidth: true,
        gutter: 15,
        prevButton: '.partners-slider__button--prev',
        nextButton: '.partners-slider__button--next',
      });

    }
    /** END PARTNERS SLIDER IN FOOTER **/


    /** POPUPS **/
    const popupTriggers = document.querySelectorAll('.popup-trigger')
    const popupOverlay = document.querySelector('.popup-overlay')

    if (popupTriggers) {
      popupTriggers.forEach(trigger => {

        trigger.addEventListener('click', e => {
          e.preventDefault()

          const popupId = trigger.getAttribute('data-popup')
          const popup = document.querySelector(`[data-popup-id="${popupId}"]`)

          document.body.style.overflow = 'hidden'
          popup.classList.add('visible')
          popupOverlay.classList.add('visible')
          bodyFixPosition()

          const closePopup = () => {
            document.body.style.overflow = null
            popupOverlay.classList.remove('visible')
            popup.classList.remove('visible')
            bodyUnfixPosition()
          }

          popup
            .querySelector('.popup__inner')
            .addEventListener('click', e => {
              if (e.target === e.currentTarget) closePopup()
            })
          popup
            .querySelector('.popup__close')
            .addEventListener('click', closePopup)
        })

      })
    }
    /** END POPUPS **/


    /** COUNTER **/
    const counterList = document.querySelectorAll('.counter')

    if (counterList) {
      let limit = 0
      const speed = 200

      window.addEventListener('scroll', () => {
        if (limit === counterList.length) return

        counterList.forEach(counter => {
          let pos = counter.getBoundingClientRect().top
          let win = window.innerHeight - 40

          if (pos < win) {
            limit++

            const to = parseInt(counter.dataset.to)
            let value = parseInt(counter.dataset.from)

            const animate = () => {
              const time = to / speed

              if (value < to) {
                value = Math.ceil(value + time)
                counter.innerText = value
                setTimeout(animate, 50)
              } else {
                counter.innerText = to
              }

            }

            animate()

          }
        })
      })
    }
    /** END COUNTER **/


  }
)
